import React from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { ScrollingProvider } from 'react-scroll-section';
import SnackbarProvider from 'react-simple-snackbar';

import configureReactReveal from 'react-reveal/globals';

import theme from '../../../theme';
import { IconStyleWrapper } from './Icons';
import { GlobalStyles } from './GlobalStyles';

configureReactReveal({ ssrFadeout: true });

export const Layout = ({ children }) => {
  return (
    <main>
      <GlobalStyles />
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <ScrollingProvider>
            <IconStyleWrapper>{children}</IconStyleWrapper>
          </ScrollingProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </main>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
