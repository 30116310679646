import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { MediaStyles } from '../Media';

export const GlobalStyles = createGlobalStyle`
  ${normalize};
  ${MediaStyles};

  ul, ol, li, img {
    margin: 0;
    padding: 0;
  }

  ul li {
    list-style-type: none;
  }
  

  * {
    margin: 0;
    padding: 0;
  }

  /**
    * Disable autofill color
    */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
  }

  body {
    font-display: swap;
    overflow-x: hidden;
  }

  p {
    line-height: 24px;
  }
`;
