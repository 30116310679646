import { createMedia } from '@artsy/fresnel';

const QueryBreakpoints = createMedia({
  breakpoints: {
    sm: 0,
    md: 568,
    lg: 1024,
    xl: 1920,
  },
});

export const MediaStyles = QueryBreakpoints.createMediaStyle();
export const { Media, MediaContextProvider } = QueryBreakpoints;
