const colors = {
  blue: "#638fdb",
  quietBlue: "rgba(56, 71, 104, 1)",
  gray: "#2d354b",
  lightGray: "#e8ecf5",
  white: "#ffffff",
  orange: "#fe6249",
  error: "#ff0100",
  lightText: "#737B92",
};

module.exports = {
  grid: {
    maxWidth: "1290px",
    horizontalPadding: "20px",
  },

  breakpoints: {
    sm: "0px",
    md: "568px",
    lg: "1024px",
    xl: "1290px",
  },

  fonts: {
    primary: "Open Sans, sans-serif",
    secondary: "Assistant, sans-serif",
  },

  fontWeights: {
    body: 400,
    heading: 600,
    bold: 600,
  },

  colors: {
    ...colors,
    input: {
      label: {
        color: colors.gray,
      },
      color: colors.gray,
    },
    button: {
      primary: {
        background: colors.orange,
        text: colors.white,
      },
      secondary: {
        background: colors.blue,
        text: colors.white,
      },
      quiet: {
        background: colors.quietBlue,
        text: colors.white,
      },
    },
    header: {
      background: colors.white,
      text: {
        color: {
          normal: colors.gray,
          active: colors.blue,
        },
      },
      icons: {
        color: colors.white,
      },
    },
    footer: {
      background: colors.blue,
      text: colors.white,
      icons: {
        color: colors.white,
      },
    },
    section: {
      dark: {
        background: colors.gray,
        text: colors.white,
      },
      intermediate: {
        background: colors.lightGray,
        text: colors.gray,
      },
      light: {
        background: colors.white,
        text: colors.gray,
      },
    },
  },
  icons: {
    size: 16,
  },
};
